import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Row, Col } from 'reactstrap';
import { Fonts, Container, TextPagesStyle } from '../global.css';

const PrivacyStatement = ({ data }) => (
  <Layout meta={data.privacyStatementEnJson.seo}>
    <Fonts>
      <TextPagesStyle>
        <Container>
          <Row>
            <Col>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.privacyStatementEnJson.content.childMarkdownRemark
                      .html,
                }}
              />
            </Col>
          </Row>
        </Container>
      </TextPagesStyle>
    </Fonts>
  </Layout>
);

PrivacyStatement.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyStatement;

export const query = graphql`
  query PrivacyStatementQuery {
    privacyStatementEnJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;
